import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    taskGroupId: String
  }

  connect() {
    console.log("edit TG reached");
    const $this = this;
    this.setupTaskGroupForm();
    this.setupVisibilityType();
    this.setupCustomPin();

    $('input[name="task_group[event_type]"]').on('click', function() {
      $this.setupCustomPin();
    })

    $('input[name="task_group[event_type]"]').on('change', function() {
      $(`${$this.taskGroupIdValue}_task_group_pin_asset_id`).val('');
    })

    $('input[name="task_group[visibility_type]"]').on('click', function(e) {
      if (confirm('Are you sure you want to change the task group type? This will remove all configured data.')) {
        $this.setupVisibilityType();
      } else {
        e.preventDefault();
        return false;
      }
    })
  }

  disconnect() {
    $("body").off("click", 'input[name="task_group[event_type]"]')
    $("body").off("change", 'input[name="task_group[event_type]"]')
    $("body").off("click", 'input[name="task_group[visibility_type]"]')
  }

  setupTaskGroupForm() {
    if ($('#task_group_choice_of_questions_true').is(':checked')) {
      $('#choice_criteria_inputs').show();
    } else {
      $('#choice_criteria_inputs').hide();
      $('#task_group_choice_criteria').val('');
    }

    if ($('#task_group_sequenced_true').is(':checked')) {
      $('.hide-on-sequenced').hide();
      
      // reset
      $('#visibility_criteria_ir_input').hide();
      $('#visibility_criteria_passcode_input').hide();
      $('#visibility_criteria_prerequisite_input').hide();

      if ($('#task_group_visibility_criteria_choice_1').is(':checked') || $('#task_group_visibility_criteria_choice_2').is(':checked')) {
        $('.task_group_expiration_criteria_minutes').show();
      } else {
        $('.task_group_expiration_criteria_minutes').hide();
        $('.task_group_expiration_criteria_minutes input').val(null);
        $('#task_group_expiration_criteria_minutes').val('').trigger('change');
      }

      $('#task_group_visibility_criteria_choice_1').prop('checked', true);
      $('.task_group_visibility_criteria_minutes_inputs input').val(null);
      $('#task_group_visibility_criteria_minutes').val('').trigger('change');
      $('#task_group_visibility_criteria_points').val('');
      $('#task_group_visibility_criteria_tribe_points').val('');
      $('#task_group_visibility_criteria_after_n_task_groups').val('');

    } else {
      $('.hide-on-sequenced').show();
    }
  }

  setupVisibilityType() {
    console.log("setup visibility reached");
    if ($('#task_group_visibility_type_image_recognition').is(':checked')) {
      this.configureForIR();
    } else if ($('#task_group_visibility_type_passcode').is(':checked')) {
      this.configureForPasscode();
    } else if ($('#task_group_visibility_type_collectables').is(':checked')) {
      this.configureForCollectable();
    } else {
    this.configureForDefaultVisibility();
    }
  }

  setupCustomPin() {
    if ($('#task_group_event_type_default').is(':checked')) {
      $('.task_group_pin_file_upload').show();
    } else {
      $('.task_group_pin_file_upload').hide();
    }
  }

  configureForDefaultVisibility() {
    $('#visibility_criteria_ir_input').hide();
    $('#visibility_criteria_passcode_input').hide();
    $('#visibility_criteria_prerequisite_input').hide();
  
    // reset passcode
    this.resetPasscodeInfo()
    this.resetIRInfo()
    this.resetCollectableInfo()
  
    if ($('#task_group_visibility_criteria_choice_1').is(':checked') || $('#task_group_visibility_criteria_choice_2').is(':checked')) {
      $('.task_group_expiration_criteria_minutes').show();
    } else {
      $('.task_group_expiration_criteria_minutes').hide();
      $('.task_group_expiration_criteria_minutes input').val(null);
      $('#task_group_expiration_criteria_minutes').val('').trigger('change');
    }
  }

  resetPasscodeInfo() {
    $('#task_group_visibility_passcode_value').val('');
    $('#task_group_visibility_passcode_supporting_text').val('');
  }

  resetIRInfo() {
    $(`${this.taskGroupIdValue}_task_group_ir_asset_id`).val('');
    $('#visibility_criteria_ir_input .filepicker_ir').show();
    $('#visibility_criteria_ir_input .filename_ir').text(null);
    $('#visibility_criteria_ir_input .file-ui_ir').hide();
    $(`${this.taskGroupIdValue}_task_group_asset_id`).val('');
    $('#visibility_criteria_ir_input .filepicker').show();
    $('#visibility_criteria_ir_input .filename').text(null);
    $('#visibility_criteria_ir_input .file-ui').hide();
    $('#task_group_ir_text').val('');
  }

  resetCollectableInfo() {
    // TODO: this
  }

  setupInformationalSettings() {
    if ($("#task_group_informational_true").is(":checked")) {
      $('.informational-settings').show();
    } else {
      $('.informational-settings').hide();
    }
  }

  configureForIR() {
    $('#visibility_criteria_ir_input').show();
    $('#visibility_criteria_passcode_input').hide();
    $('#visibility_criteria_prerequisite_input').hide();
  
    this.resetPasscodeInfo()
    this.resetCollectableInfo()
  }

  configureForPasscode() {
    $('#visibility_criteria_ir_input').hide();
    $('#visibility_criteria_prerequisite_input').hide();
    $('#visibility_criteria_passcode_input').show();
  
    this.resetIRInfo()
    this.resetCollectableInfo()
  }

  configureForCollectable() {
    $('#visibility_criteria_ir_input').hide();
    $('#visibility_criteria_passcode_input').hide();
    $('#visibility_criteria_prerequisite_input').show()
  
    this.resetIRInfo()
    this.resetPasscodeInfo()
  }
}