import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { expanded: Boolean }
  static targets = [ "database", "taskGroup" ]

  jumpToTop() {
    if (this.expandedValue) { return; }
    window.scrollTo(0, 0);
  }

  closeTaskGroup() {
    if (this.hasTaskGroupTarget) {
      this.taskGroupTarget.remove()
    }
    this.showDatabase()
  }

  hideDatabase() {
    this.databaseTarget.classList.add("hidden")
  }

  showDatabase() {
    this.databaseTarget.classList.remove("hidden")
  }
}
