import { Controller } from "stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static values = { delay: Number }
  static targets = ["wrapper"]

  initialize() {
    this.close = this.close.bind(this)
  }

  connect() {
    enter(this.wrapperTarget)

    this.timeout = setTimeout(this.close, this.delayValue || 3000)
  }

  close() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    leave(this.wrapperTarget).then(() => {
      // Remove the modal element after the fade out so it doesn't blanket the screen
      this.element.remove()
    })
  }
}
