import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    taskId: String
  }

  connect() {
    let taskId;
    if (this.taskIdValue == "creation") {
      taskId = ""
    } else {
      taskId = this.taskIdValue
    }
    if (this.taskController) {
      this.taskController.setupTagAutoCompleter("#" + taskId + "_categories")
      this.taskController.setupTagAutoCompleter("#" + taskId + "_themes")
      this.taskController.hideAndShowMap();
    }
  }

  get taskController() {
    return this.application.getControllerForElementAndIdentifier($(`#task_${this.taskIdValue}.accordion-group`)[0], 'task')
  }
}