import { Controller } from 'stimulus';

export class BaseStepController extends Controller {
  refreshStepOrder(task_id) {
    // Visual
    let task_container = $('#collapse_task_' + task_id);
    let step_index;
    $.each(task_container.find('.step-number'), function(idx, item) {
      step_index = idx + 1;
      $(item).text('Step ' + step_index);
    });
    // Form/data
    $.each(task_container.find('.step_index'), function(idx, item) {
      step_index = idx + 1;
      $(item).val(step_index);
    });
  }

  hideAndShowAddNewStep(task_id, force) {
    var closest_step = $('#collapse_task_' + task_id + '').find('.accordion-group').children().last().find('.step-type-icon').attr('title')
    if (closest_step == 'Unlock Step') {
      $('#collapse_task_' + task_id + '').find('.add_new_step').addClass('unlock-hidden').hide();
      $('#collapse_task_' + task_id + '').find('.unlock-step-msg').show();
    } else {
      $('#collapse_task_' + task_id + '').find('.unlock-step-msg').hide();
      $('#collapse_task_' + task_id + '').find('.add_new_step .unlock-hidden').show().removeClass('unlock-hidden');
      if (force) {
    $('#collapse_task_' + task_id + '').find('.add_new_step').show();
        }
      }
  }
}