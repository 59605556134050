import { BaseStepController } from './base_step_controller.js'

export default class extends BaseStepController {
  static targets = [
    "form",
    "assetType",
    "chooseFile"
  ]

  static values = {
    gameId: String,
    taskGroupId: String,
    taskId: String,
    tasksPath: String,
    stepId: String,
    stepType: String,
    noNewStepButton: Boolean
  }

  connect() {
    $(".pop-over").popover({trigger: 'hover'});

    if (!this.noNewStepButtonValue) {
      this.hideAndShowAddNewStep(this.taskIdValue, true);
    }
    this.refreshStepOrder(this.taskIdValue);
    configurePermanent(this.taskIdValue);

    const $this = this
    // asset upload
    $('body').on('change', '.step_asset_type', function(event) {
      $this.handleAssetTypeSelection(event);
    });

    $('body').on('change', `#step_form_${this.stepIdValue}_step_file`, function(event) {
      $this.chooseFileChanged(event);
    });

    // setup bootstrap switches
    let stepId = "";
    if (this.stepIdValue != "new_step") {
      stepId = this.stepIdValue
    }

    if ($(`#bootstrap-switch-${stepId}.has-switch`).length === 0) {
      $(`#bootstrap-switch-${stepId}`).bootstrapSwitch();
    }

    if ($(`#bootstrap-switch-${stepId}-images.has-switch`).length === 0) {
      $(`#bootstrap-switch-${stepId}-images`).bootstrapSwitch();
    }

    this.matchTypeToggled($(`#bootstrap-switch-${stepId}`));

    window.toggleStepChallengeFields(this.stepTypeValue, $(this.formTarget));
    window.hideAndShowTimeLimit($(`#${stepId}_time_limit`));
  }

  matchTypeToggled(stepSwitch) {
    window.ignoreChanges = true

    let activeForm = $(stepSwitch).closest('form');
    if ($(stepSwitch).bootstrapSwitch('status')) {
      $(activeForm).find('.match_type').hide()
      $(activeForm).find('.answer_count').hide()
      $(activeForm).find('.image-answers').show()
      $(activeForm).find('.multi_choice_reward_type').show()
      $(activeForm).find('.answer_count').hide()
    } else {
      $(activeForm).find('.multi_choice_reward_type_option_boolean').click()
      $(activeForm).find('.match_type').show()
      $(activeForm).find('.answer_count').show()
      $(activeForm).find('.image-answers').hide()
      $(activeForm).find('.multi_choice_reward_type').hide()
      $(activeForm).find('.bootstrap-switch-answers').bootstrapSwitch('setState', false)
      $(activeForm).find('.answer_count').show()
    }
    toggleMultiChoicePoints($(activeForm).find('.multi_choice_reward_type_option:checked'))

    window.ignoreChanges = null
  }

  handleAssetTypeSelection(event) {
    let option = $(event.target).find(':selected');
    let activeForm = $(event.target).closest('form');
    let upload_div = activeForm.find('.step_file_upload')[0];
    let duration_div = activeForm.find('.asset-duration')[0];
    resetUploadForForm(activeForm);
    if (option.val() == "None") {
      $(upload_div).hide();
      $(duration_div).hide();
      $(duration_div).find('input').val(0);
    } else {
      if (option.val() != "Video") {
        $(duration_div).show();
        $(duration_div).find('input').val(0);
      } else {
        $(duration_div).hide();
        $(duration_div).find('input').val(0);
      }
      $(upload_div).show();
    }
  }

  chooseFileChanged(event) {
    let activeForm = document.getElementById(`step_form_${this.stepIdValue}`);

    $(activeForm).find('.file-upload-error').hide();

    let extName;
    let maxFileBytes = 4194304;
    let maxVideoBytes = 20971520;
    let maxFileSize = bytesToSize(maxFileBytes);
    let maxVideoSize = bytesToSize(maxVideoBytes);
    let maxExceededMessage = "This file exceeds the maximum allowed file size" + ' (' + maxFileSize + ')';
    let maxVideoExceededMessage = "This file exceeds the maximum allowed file size" + ' (' + maxVideoSize + ')';
    let allowedExtension = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    let sizeExceeded = false;
    let videoSizeExceeded = false;
    let isVideo = false;
    let fileSize = document.getElementById(`step_form_${this.stepIdValue}_step_file`).files[0].size

    if (fileSize && maxFileBytes && fileSize > parseInt(maxFileBytes)) {
      sizeExceeded=true;
    }

    if (fileSize && maxVideoBytes && fileSize > parseInt(maxVideoBytes)) {
      videoSizeExceeded=true;
    }

    extName = $(`#step_form_${this.stepIdValue}_step_file`).val().split('.').pop();
    if ($.inArray(extName, allowedExtension) == -1) {
      isVideo=true;
    }

    // TODO: re-write this logic to be more readable
    if ((isVideo && videoSizeExceeded) || (!isVideo && sizeExceeded)) {
      if (isVideo){
        if (videoSizeExceeded) {
          $(`#step_form_${this.stepIdValue}_step_file`).val('');
          $(activeForm).find('.file-upload-error').text(maxVideoExceededMessage).show();
        }
      } else {
        if (sizeExceeded) {
          $(`#step_form_${this.stepIdValue}_step_file`).val('');
          $(activeForm).find('.file-upload-error').text(maxExceededMessage).show();
        }
      }
    } else {
      uploadStepAsset($(activeForm));
    }
  }

  desiredResponsePrompt(form) {
    // If no response
    if (form.find('select#step_type')[0] == undefined) {
      return true;
    }
    if (form.find('select#step_type')[0].value == 'ChallengeStep') {
      return confirm('Are you sure you want to save this challenge with no desired response from the user?');
    } else {
      return true;
    }
  }

  submitForm(event) {
    startSaving();
    const $this = this;
    let activeForm = $(this.formTarget)
    let newWholeStepForm = activeForm.closest("#_new_step_creation")
    if (activeForm.find('.create-task').length == 0) { // Saving on an existing task
      // let the form submit via turbo
      const $this = this
      if (!this.desiredResponsePrompt(activeForm)) {
        return false;
      }
      isLoadingEditor = false;
      endLoading();
      endSaving();
    } else {
      event.preventDefault();
      // Saving on a new task so must save nearby task first, populate our task_id and then save the form
      let activeTaskForm = activeForm.closest('#collapse_task_').find('#new_task');
      let taskGroupContainer = activeTaskForm.closest(`#${this.taskGroupIdValue}_tasks`)
      let taskId;
      let observer = new MutationObserver((changes) => {
        changes.forEach(change => {
          if (change.addedNodes != undefined) {
            // listen for when the turbo frame is added with the task id
            change.addedNodes.forEach((node) => {
              if(node.id !== undefined && node.id.length > 0) {
                const re = /task_\d/
                if (re.test(node.id)) {
                  taskId = /\d+/.exec(node.id)
                  let formAction = $(activeForm[0]).attr("action")
                  const newAction = formAction += `&task_id=${taskId}&new_created_task=true`
                  // set the taskId on the step form
                  activeForm[0].setAttribute("action", newAction)
                  // reattach the form to the DOM (in the new task/show) so we can submit
                  $(node).find(`#collapse_task_${taskId}`).find(".accordion-inner")[0].appendChild(newWholeStepForm[0])
                  $(node).find(`#collapse_task_${taskId}`).find(".add_new_step").hide()
                  activeForm[0].requestSubmit()

                  observer.disconnect();
                  this.refreshStepOrder(taskId);
                  $this.taskGroupsListController.loadTaskGroupList(openMapID);
                  $(node).closest('#task_group_data').find('#new-task').show();
                  waitingOnTaskSave = false;
                  endLoading();
                  endSaving();
                }
              }
            })
          }
        })
      })
      observer.observe(taskGroupContainer[0], {attributes: true, childList: true, subtree: true})

      activeTaskForm[0].requestSubmit() // save the task
    }
    return false;
  }

  revertChanges() {
    this.formTarget.reset();
    $(this.formTarget).find('#task_theme_list').trigger('change');
    $(this.formTarget).find('#task_category_list').trigger('change');
  }

  chooseFileClicked(event) {
    $(event.target).val(null)
  }

  get taskGroupsListController() {
    return this.application.getControllerForElementAndIdentifier($("#task_group_list_data")[0], 'task-groups-list')
  }
}