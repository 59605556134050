import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    gameId: String,
    newTaskPath: String,
    taskGroupId: String
  }

  connect() {
    window.initSortableTasks(this.taskGroupIdValue)
  }

  addTaskToTaskGroup() {
    let task_group_id = this.taskGroupIdValue
    startLoading();

    $($('#' + task_group_id + '_tasks').find('#new-task')).hide();
  }
}