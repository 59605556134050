import { BaseStepController } from './base_step_controller.js'

export default class extends BaseStepController {
  static targets = [
    "container"
  ]
  static values = {
    taskId: String,
    stepId: String
  }

  connect() {
    $(`a#step-accordion_${this.stepIdValue}`).click(function(){
      $(this).toggleClass('step-active');
    });
    this.hideAndShowAddNewStep(this.taskIdValue, true)
    const $this = this;
    this.containerTarget.addEventListener('step-deleted', function (event) {
      $this.refreshStepOrder($this.taskIdValue)
    });
  }

  disconnect() {
    const deletedEvent = new CustomEvent('step-deleted', { bubbles: true })
    this.containerTarget.dispatchEvent(deletedEvent)
  }

  deleteStepButtonPressed(event) {
    event.preventDefault();

    if (confirm('Are you sure you want to delete this step?')) {
      $(event.target).closest(".button_to")[0].requestSubmit();
    }
    return false;
  }
}