import { Controller } from 'stimulus'
import * as L from 'leaflet';
import 'leaflet-defaulticon-compatibility';

export default class extends Controller {
  static values = {
    imageUrl: String,
    width: Number,
    height: Number
  }
  static targets = [
    "container"
  ]

  connect() {
    this.loadMap()
  }

  disconnect() {
    this.map.remove()
  }

  loadMap() {
    this.map = L.map(this.containerTarget, {
      crs: L.CRS.Simple,
      minZoom: -1,
      maxZoom: 1
    })

    var yx = L.latLng;
    var xy = function(x, y) {
      if (L.Util.isArray(x)) { // When doing xy([x, y]);
        return yx(x[1], x[0]);
      }
      return yx(y, x); // When doing xy(x, y);
    };

    // calc size
    this.bounds = [xy(0, 0), xy(this.widthValue, this.heightValue)];
    this.map.setMaxBounds(this.bounds);

    // load image
    this.image = L.imageOverlay(this.imageUrlValue, this.bounds)
    this.image.addTo(this.map);
    this.map.setView(xy(0, this.heightValue), 0)
  }

  onClick(layer) {
    console.log(layer)
  }
}
