import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "name",
    "form",
    "addNewStep"
  ]

  static values = {
    gameId: String,
    taskId: String,
    taskGroupId: String,
    reorderTaskGroups: Boolean
  }

  connect() {
    if (this.reorderTaskGroupsValue) {
      $(`.steps_accordion_${this.taskIdValue}`).sortable({
        items: ".step-body",
        handle: '.accordion-heading',
        cursor: "move",
        stop: (event, ui) => {
          saveCurrentStepOrder(this.taskIdValue)
        }
      });
      window.initSortableTasks(this.taskGroupIdValue)
    }

    const $this = this
    $('body').on('click', '.delete-new-step', function(event){
      $this.closeAllNewSteps(event)
    })

    this.setupTagAutoCompleter("#" + this.taskIdValue + "_categories")
    this.setupTagAutoCompleter("#" + this.taskIdValue + "_themes")

    if (this.gameIdValue == "") {
      this.hideAndShowMap();
    }
  }

  setupTagAutoCompleter(id, placeholder = "", min_length = 3) {
    $(id).select2({
      placeholder: placeholder,
      minimumInputLength: min_length,
      tokenSeparators: [',', ' '],
      width: 'resolve'
    });
  }

  closeAllNewSteps(event) {
    $(event.target).parents().eq(4).find('.add_new_step').show();
    $(event.target).closest('.new_step_creation').html('');
    delete unsavedForms['new_step'];
    endSaving();
    return false;
  }

  saveTask(event) {
    startSaving();
    event.preventDefault();
    let form = $(event.target).closest('.task-settings').find('form');
    // set the name of the task in outer heading
    if (this.hasNameTarget) {
      this.nameTarget.innerHTML = form[0][2].value;
    }
    
    form[0].requestSubmit()
    if ($('#search_options_in_game_tasks').prop('checked')) {
      loadTaskDatabase();
    }
    if (this.gameIdValue == "") {
      loadTaskLibraryDatabase()
    }
    if (this.taskGroupsListController) {
      this.taskGroupsListController.loadTaskGroupList(openMapID);
    }
    endSaving();
  }

  revertChanges() {
    this.formTarget.reset();
    $(this.formTarget).find('#task_theme_list').trigger('change');
    $(this.formTarget).find('#task_category_list').trigger('change');
  }

  deleteTask(event) {
    const $this = this;
    if (confirm('Are you sure you want to remove this task from this task group?')) {
      event.target.requestSubmit()
      $(event.target).closest('#task_group_data').find('#new-task').show();
      startSaving();
      $this.taskDatabaseController.loadTaskDatabase();
      setTimeout(function () { // give the form time to respond
        $this.taskGroupsListController.loadTaskGroupList(openMapID);
      }, 1000)
      endSaving();
    } else {
      event.preventDefault()
    }
  }

  removeNewTask(event) {
    if (confirm('Are you sure you want to remove this task from this task group?')) {
      // Show new task button
      $(event.target).closest('#task_group_data').find('#new-task').show();
      // Remove accordion
      $(event.target).closest('.task-accordion').html('');
    }
  }

  toggleCollapseSteps() {
    let steps = $('#task_' + this.taskIdValue).find('.step.accordion-toggle')

    $(steps).each(function(i, obj) {
      $(obj).click();
    });
  }

  addStepPressed(event) {
    if (!window.localizationIsHappyForEdits) {
      localizeError();
      return false;
    }

    $.each($('.delete-new-step'), function(idx, item) {
      $(item).trigger('click');
    });

    startLoading();
    $(this.addNewStepTarget).hide();
    isUnsaved();
    return false;
  }

  hideAndShowMap() {
    if ($('input[name="task[location_specific]"]').is(':checked')) {
      $('#location_specific_map_container').show();
      setupLocationSpecMap();
    } else {
      $('#location_specific_map_container').hide();
    }
  }

  get taskGroupsListController() {
    return this.application.getControllerForElementAndIdentifier($("#task_group_list_data")[0], 'task-groups-list')
  }

  get taskDatabaseController() {
    return this.application.getControllerForElementAndIdentifier($("#task_database_data")[0], 'task-database')
  }
}