import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    hiddenClass: String,
    dependencyMap: Object
  }

  toggle(event) {
    Object.entries(this.dependencyMapValue).forEach(([value, domClass]) => {
      const elementToToggle = this.element.getElementsByClassName(domClass)[0];

      if (value === event.currentTarget.value) {
        elementToToggle.classList.remove(this.hiddenClassValue);
      } else {
        elementToToggle.classList.add(this.hiddenClassValue);
      }
    })
  }
}