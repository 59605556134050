import { BaseStepController } from './base_step_controller'

export default class extends BaseStepController {
  static values = {
    taskId: String
  }

  selectType(event) {
    const taskId = this.taskIdValue
    $(`#new_task_${taskId} .step-type-icon.picker`).removeClass('selected');
    $(`#${taskId}_tip`).fadeOut();
    $(event.target).addClass('selected');
    startLoading();
    this.refreshStepOrder(taskId);
    isUnsaved();
  }

  cancelNewStep(event) {
    $(event.target).parents().eq(4).find('.add_new_step').show();
    $(event.target).closest('.new_step_creation').html('');
    delete unsavedForms['new_step'];
    endSaving();
    return false;
  }
}