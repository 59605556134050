import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "button", "expandable" ]

  connect() {
    this.expanded = false
    this.collapsedButtonText = this.buttonTarget.getAttribute("data-expanded-editor-collapsed-text")
    this.expandedButtonText = this.buttonTarget.getAttribute("data-expanded-editor-expanded-text")
  }

  toggleExpanded() {
    this.expanded = !this.expanded

    this.expandableTargets.forEach((el) => {
      let classList = el.getAttribute('data-expanded-editor-class-list').split(" ")
      if (this.expanded) {
        el.classList.add(...classList)
      } else {
        el.classList.remove(...classList)
      }
    })
    this.buttonTarget.innerHTML = this.expanded ? this.expandedButtonText : this.collapsedButtonText
  }
}
