import { Controller } from "stimulus"

export default class extends Controller {
  loadTaskGroupList(event) {
    let mapId = event.target.id.replace("map_", "");
    if (typeof openMapId !== 'undefined') { openMapId = mapId }
    this.taskGroupListController.loadTaskGroupList(mapId, null);
  }

  get taskGroupListController() {
    return this.application.getControllerForElementAndIdentifier($("#task_group_list_data")[0], 'task-groups-list')
  }
}