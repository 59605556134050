import { Controller } from "stimulus"

export default class extends Controller {
  // TASK DB
  // var selected_tasks = []
  // var taskDatabaseConfiguration = {}
  
  connect () {
    console.log("task database connect reached");
    this.loadTaskDatabase(null)
    this.createTaskDatabaseEventHandlers()
  }

  loadTaskDatabase(callback) {
    startLoading()

    let data = jQuery.extend(true, {}, taskData)
    data['search_options'] = {
      language: $('#search_options_language').val(),
      tag_query: $('#tag_query').val(),
      task_query: $('#search_options_description').val(),
      standard_tasks: $('#search_options_standard_tasks').prop('checked') ? 1 : 0,
      in_game_tasks: $('#search_options_in_game_tasks').prop('checked') ? 1 : 0,
      location_specific_tasks: $('#search_options_location_specific_tasks').prop(
        'checked'
      )
        ? 1
        : 0,
      client_specific_tasks: $('#search_options_client_specific_tasks').prop(
        'checked'
      )
        ? 1
        : 0,
    }
  
    if (map != null) {
      let bounds = map.getBounds()
      data['search_options']['map_bounds'] = {
        north_east: {
          latitude: bounds.getNorthEast().lat(),
          longitude: bounds.getNorthEast().lng(),
        },
        south_west: {
          latitude: bounds.getSouthWest().lat(),
          longitude: bounds.getSouthWest().lng(),
        },
      }
    }
  
    $.ajax({
      method: 'GET',
      url: $("#task_database_data").data("task-database-url"),
      data: data,
      cache: false,
      success: function (html) {
        $('#task_database_data').html(html)
        if (map != null) {
          matchLocationWithCheckbox()
        }
        endLoading()
        $('.pop-over').popover({ trigger: 'hover' })
        if (callback != null) callback(null, null)
      },
      error: function (xhr, ajaxOptions, thrownError) {
        handleEditorError(thrownError)
        endLoading()
        if (callback != null) callback(thrownError, null)
      },
    })
  } // end loadTaskDatabase
  
  clearTaskDBSelection() {
    $.each(selected_tasks, function (l_id, task) {
      task_id = task.value.replace('task_db_', '')
      $('#task_database_data')
        .find('input[id="task_db_checkbox_' + task_id + '"]')
        .parents()
        .eq(0)
        .removeClass('selected')
      $('#task_database_data')
        .find('input[id="task_db_checkbox_' + task_id + '"]')
        .prop('checked', false)
    })
  
    if (map != null) {
      matchLocationWithCheckbox()
    }
  }
  
  selectFromTaskDB() {
    this.clearTaskDBSelection()
    let tasksShown = $('#task_database_data')
      .find('.task-db-entry')
      .not('.ui-draggable-dragging')
    $.each($(tasksShown), function (idx, db_entry) {
      let checkbox = $(db_entry).find('input[name="task_checkbox"]')
      if (checkbox.length != 0) {
        $(db_entry).addClass('selected')
        $(checkbox).prop('checked', true)
      }
    })
    if (map != null) {
      matchLocationWithCheckbox()
    }
  }
  
  // TASK
  addExistingTaskToTaskGroup(task_group_id, task_id) {
    // GET LOCATION ID OF TASK ID
    // ASYNC TASK TO DO IT
    const $this = this;
  
    startSaving()
  
    let location_id = $('#task_db_' + task_id).hasClass(
      'task-db-entry-location-specific'
    )
      ? $('#task_db_' + task_id).attr('location')
      : null
    let data = taskData
    data = { task_group_id: task_group_id, task_id: task_id, game_id: taskData["game_id"] }
    $.ajax({
      type: 'PUT',
      url: taskDatabaseConfiguration['add_task_task_groups_url'],
      data: data,
      cache: false,
      success: function (html) {
        async.parallel(
          [
            function (callback) {
              if (location_id) {
                addLocationToTaskGroup(
                  task_group_id,
                  location_id,
                  function (callback) {
                    if (map) {
                      reloadMap(callback)
                    }
                  }
                )
              } else {
                callback(null, null)
              }
            },
            function (callback) {
              $this.taskGroupListController.loadTaskGroupList("stick", callback)
              if (map) {
                reloadMap(callback)
              }
            },
            function (callback) {
              $this.loadTaskDatabase(callback)
            },
          ],
          function (err, results) {
            endSaving()
          }
        )
      },
      error: function (xhr, ajaxOptions, thrownError) {
        endSaving()
        handleEditorError(thrownError)
      },
    })
  }
  
  createTaskDatabaseEventHandlers() {
    const $this = this;
    $('body').on('change', '#tag_query', function () {
      taskData['page'] = null
      $this.loadTaskDatabase()
      return false
    })
  
    $('body').on('keydown', '#search_options_description', function (e) {
      if (e.keyCode == 13) {
        taskData['page'] = null
        $this.loadTaskDatabase()
        return false
      }
    })
  
    $('body').on('change', '#search_options_language', function () {
      taskData['page'] = null
      $this.loadTaskDatabase()
      return false
    })
  
    $('body').on('change', '#search_options_standard_tasks', function () {
      taskData['page'] = null
      $this.loadTaskDatabase()
      return false
    })
  
    $('body').on('change', '#search_options_included_tasks', function () {
      taskData['page'] = null
      $this.loadTaskDatabase()
      return false
    })
  
    $('body').on('change', '#search_options_in_game_tasks', function () {
      taskData['page'] = null
      $this.loadTaskDatabase()
      return false
    })
  
    $('body').on(
      'change',
      '#search_options_location_specific_tasks',
      function () {
        taskData['page'] = null
        $this.loadTaskDatabase()
        return false
      }
    )
  
    $('body').on('change', '#search_options_client_specific_tasks', function () {
      taskData['page'] = null
      $this.loadTaskDatabase()
      return false
    })
  
    $('body').on('click', '.tag-btn', function () {
      let tag = $(this).text()
      if ($('#tag_query').val()) {
        newValue = $('#tag_query').val()
        newValue.push(tag)
      } else {
        newValue = new Array(tag)
      }
      $('#tag_query').val(newValue).trigger('change')
    })
  
    $('body').on('click', 'a.task-accordion-heading', function () {
      if ($(this).hasClass('task-active')) {
        $(this).removeClass('task-active')
      } else {
        $('a.accordion-heading').removeClass('task-active')
        $(this).addClass('task-active')
      }
    })
  
    $('body').on('click', '.reset-locations-btn', function () {
      clearPinSelection()
    })
  
    $('body').on('click', '.reset-tasks-btn', function () {
      $this.clearTaskDBSelection()
    })
  
    $('body').on('click', '.select-all-tasks-btn', function () {
      $this.selectFromTaskDB()
    })
  } // createTaskDatabaseEventHandlers

  get taskGroupListController() {
    return this.application.getControllerForElementAndIdentifier($("#task_group_list_data")[0], 'task-groups-list')
  }
}
