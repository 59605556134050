import { Controller } from "stimulus"
import TomSelect from "tom-select";

export default class extends Controller {
  static values = { url: String };
  static targets = ['field'];

  connect() {
    new TomSelect(this.fieldTarget, {
      valueField: 'name',
      labelField: 'name',
      searchField: ['name'],
      preload: true,
      load: function(query, callback) {
        fetch(this.urlValue + '?q=' + encodeURIComponent(query))
          .then(response => response.json())
          .then(json => {
            callback(json.data);
          }).catch(()=>{
            callback();
          });
      }.bind(this),
      // custom rendering function for options
      render: {
        option: function(item, escape) {
          return `<div class="py-2 d-flex">
                <div class="mb-1">
                  <span class="h5">
                    ${ escape(item.name) }
                  </span>
                </div>`
                + `${ item.description ? `<div class="ms-auto">${ escape(item.description) }</div>` : '' }` +
              `</div>`;
        }
      },
    });
  }
}
