import { Controller } from 'stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  static values = {
    animation: Number,
    handle: String
  }

  initialize () {
    this.end = this.end.bind(this)
  }

  connect () {
    this.sortable = new Sortable(this.element, {
      ...this.defaultOptions,
      ...this.options
    })
  }

  disconnect () {
    this.sortable.destroy()
    this.sortable = undefined
  }

  end ({ item, newIndex }) {
    let form = item.querySelector("#position_form")
    if (!form) return;

    let positionEl = form.querySelector("[name$='[position]']")
    positionEl.value = newIndex + 1
    form.requestSubmit()
  }

  get options () {
    return {
      animation: this.animationValue || this.defaultOptions.animation || 150,
      handle: this.handleValue || this.defaultOptions.handle || undefined,
      onEnd: this.end
    }
  }

  get defaultOptions () {
    return {}
  }
}
